.mainBtn:hover{
    background-color: rgba(56,55,55,.75) !important;
    border-radius: 1rem;
    border-color:  #fdbe2b !important;
    color:#fdbe2b !important;
}

::-webkit-scrollbar {
    width: 5px;
    margin-right: -10px;
}
/*!* Track *!*/
::-webkit-scrollbar-track {
    background-color: #292b2c;
    /*border: 1px solid black;*/
    border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: white;
}

.line-clamp2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

img {
    user-drag: none;
}

.opacity_bg {
    z-index: -9999;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    transition: background 3s linear !important;
}
.opacity_bg::before{
    content: "";
    /*opacity: 0.5;*/
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    /*background-color: #000;*/
    /*background-color: rgba(128,128,128,0.75);*/
    background-color: rgba(0,0,0,0.6);
}
