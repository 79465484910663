body {
  margin: 0;
  padding:0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay !important;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slide-enter {
  transform: translateX(100%);
}
.slide-enter-active {
  transform: translateX(0%);
  transition: transform 2000ms ease-in-out;
}
.slide-exit {
  transform: translateX(0%);
}
.slide-exit-active {
  transform: translateX(-100%);
  transition: transform 2000ms ease-in-out;
}

.no_sb {
  scrollbar-width: none;
}
.no_sb::-webkit-scrollbar {
  display: none;
}

.rdp {
  --rdp-cell-size: 2.8vw !important;
}

.time_range_picker {
  width: 100%;
}

.react-timerange-picker__wrapper {
  border: 1px solid lightgray !important;
  text-align: center;
}

@import "~bootstrap/dist/css/bootstrap.min.css";
